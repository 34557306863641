import {
  hubspotOnFormReady as onFormReady,
  restyleHubspotForm as restyleForm,
  createForm,
} from '../../ably-ui/hubspot-forms';

const setupHubSpotForms = ({ userSignedIn }) => {
  const config = {
    supportAnonymous: {
      formId: 'edc0c193-801d-4410-8b30-35531a8ddbaf',
      btn: '#support-radio',
      container: '#hubspot-raise-a-ticket-form',
      title: 'Raise a support ticket',
    },
    support: {
      formId: '57de6eaf-7093-441e-97cc-f61292c96ac4',
      btn: '#support-radio',
      container: '#hubspot-raise-a-ticket-form',
      title: 'Raise a support ticket',
    },
    incident: {
      formId: '61e8853c-d5ba-4ad8-8a52-fc555c15cc0c',
      btn: '#incident-escalation-radio',
      container: '#hubspot-raise-a-incident-escalation-form',
      title: 'Incident escalation',
    },
  };

  const formKeys = Object.keys(config);
  const isContainerEmpty = key => document.querySelector(config[key].container).innerHTML === '';
  const getContainer = key => document.querySelector(config[key].container);

  const renderForm = key => {
    createForm({ formId: config[key].formId, target: config[key].container, onFormReady });
    restyleForm(`${config[key].container} > iframe`);
  };

  const showForm = key => {
    formKeys.filter(k => k !== key).forEach(k => getContainer(k).classList.add('hidden'));
    const container = getContainer(key);
    container.classList.remove('hidden');

    if (isContainerEmpty(key)) renderForm(key);
  };

  const supportBtn = document.querySelector('[data-id="raise-a-support-ticket-btn"]');
  const enterpriseSupportBtn = document.querySelector('[data-id="raise-a-support-ticket-enterprise-btn"]');
  const enterpriseForm = document.querySelector('[data-id="hubspot-choose-form"]');

  if (supportBtn) {
    const supportBtnText = supportBtn.innerText;

    if (userSignedIn) {
      renderForm('support');
    } else {
      renderForm('supportAnonymous');
    }

    supportBtn.addEventListener('click', () => {
      const container = getContainer('support');

      if (container.classList.contains('hidden')) {
        container.classList.remove('hidden');
        supportBtn.innerText = 'Hide support ticket form';
      } else {
        container.classList.add('hidden');
        supportBtn.innerText = supportBtnText;
      }
    });
  }

  if (enterpriseSupportBtn) {
    const enterpriseSupportBtnText = enterpriseSupportBtn.innerText;
    const severityForms = formKeys.slice(1);

    severityForms.forEach(key => {
      const btn = document.querySelector(config[key].btn);

      if (btn) {
        renderForm(key);
        btn.addEventListener('click', () => showForm(key));
      }
    });

    enterpriseSupportBtn.addEventListener('click', () => {
      if (enterpriseForm.classList.contains('hidden')) {
        enterpriseForm.classList.remove('hidden');
        getContainer('support').classList.remove('hidden');
        document.querySelector('#support-radio').checked = true;
        enterpriseSupportBtn.innerText = 'Hide support ticket form';
      } else {
        enterpriseForm.classList.add('hidden');
        formKeys.forEach(k => getContainer(k).classList.add('hidden'));
        enterpriseSupportBtn.innerText = enterpriseSupportBtnText;
      }
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const userSignedIn = document.body.dataset.signedIn === 'true';

  setupHubSpotForms({ userSignedIn });
});
